@import "../../styles/variables.scss";

.footer {
  background-color: $lightgray;
  color: $darkgray;
  .inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 240px);
    margin: 0 auto;
    padding: 120px 0;
    .col {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 40%;
      .wrap {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-right: 0px;
        width: 50%;
        &:last-child {
          margin-right: 0;
        }
        .label {
          display: flex;
          width: calc(100% - 30px);
          border-bottom: 2px solid $darkgray;
          padding-bottom: 10px;
          margin-bottom: 20px;
          h3 {
            font-size: 1.3rem;
            margin: 0px;
            padding-top: 4px;
            padding-left: 0px;
          }
          .logo {
            position: relative;
            width: 40%;
            max-width: 80px;
            margin-right: 8px;
          }
        }
        .social {
          margin-left: -10px;
          &.mobileOnly {
            display: none;
          }
          &.desktopOnly {
            display: block;
          }
          a {
            display: inline;
          }
        }
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            font-size: 1em;
            margin: 0;
            line-height: 1.5;
          }
        }
        p {
          font-size: 1em;
          width: 90%;
          line-height: 1.5;
          margin: 0 auto 20px 0;
        }
        a {
          display: block;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1465px) {
  .footer {
    .inner {
      width: calc(100% - 70px);
      .col {
        width: 50%;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .footer {
    .inner {
      flex-direction: column;
      padding: 60px 0;
      .col {
        width: 100%;
        flex-direction: column;
        .wrap {
          width: 100%;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          .social {
            &.desktopOnly {
              display: none;
            }
            &.mobileOnly {
              display: block;
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }
}
