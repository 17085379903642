@import "../../styles/variables.scss";

.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: rgba(112, 112, 112, 0.75);
  backdrop-filter: blur(50px);

  .error {
    color: $yellow;
  }

  .closeWrapper {
    position: relative;
    background: #080808 0% 0% no-repeat padding-box;
    width: 100vw;
    //padding: 20px;
    display: flex;
    justify-content: space-between;
    height: 120px;
    .close {
      display: flex;
      position: relative;
      padding: 20px;
      .hamburgerButton {
        position: relative;
        display: none;
        height: 35px;
        width: 35px;
        align-self: center;
        margin-right: 10px;
      }
    }
    .image {
      position: relative;
      height: auto;
      width: 150px;
      margin-left: 30px;
    }
  }

  .modal {
    margin-top: 0px;
    width: calc(100vw + 18px);
    height: 100vh;
    opacity: 1;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    .header {
      position: relative;
      width: 100%;
      max-width: 750px;
      padding-top: 60px;
      padding-bottom: 20px;
      h2 {
        font-size: 4rem;
        line-height: 1;
        text-align: center;
        margin: 0;
        color: white;
      }
      p {
        @include regular-p;
      }
    }
    .subText {
      position: relative;
      width: 100%;
      max-width: 600px;

      p {
        @include regular-p;
        text-align: center;
        margin: 0;
        color: white;
      }
    }
    .formWrapper {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      padding-top: 30px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      max-width: 1000px;
      margin-bottom: 200px;
      .imageWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 15px;
        width: calc(35% - 40px);
        margin-right: 40px;
        .image {
          position: relative;
          width: 100%;
          video {
            position: relative;
            width: 100%;
          }
        }
        .subText {
          position: relative;
          padding-top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            color: $yellow;
            font-size: 1rem;
            line-height: 1;
            width: 100%;
          }
        }
      }
      .form {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 65%;

        p.disclaimer {
          color: $yellow;
          margin-bottom: 0;
        }

        .formSent {
          p {
            font-size: 1.4rem;
            color: $yellow;
          }
        }

        .formMsg {
          margin-top: 20px;
          p {
            font-size: 1.2rem;
            color: $yellow;
            font-family: $maax-bold;
          }
        }

        .personalInfo {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-bottom: 20px;

          .sideBySide {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            :nth-child(1) {
              margin-right: 10px;
            }
            width: 100%;
            .dropdown {
              width: 70px;
            }
          }
          .sideBySideNum {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            :nth-child(1) {
              margin-right: 10px;
            }
            .item {
              display: flex;
              width: 100%;
              .dropdown {
                width: 70px;
              }
            }
          }
          .address {
            width: 100%;
            padding-top: 12px;
          }
          .button {
            width: 100%;
            padding-top: 30px;
          }
          .questions {
            width: 100%;
            height: 100%;
            display: flex;
            min-height: 200px;
          }
        }
      }
    }
  }
}
@media all and (max-width: 767px) {
  .modalContainer {
    .closeWrapper {
      position: relative;

      .close {
        .backButton {
          display: none;
        }
        .hamburgerButton {
          display: flex;
        }
      }
    }

    .modal {
      width: 100%;
      height: 100%;
      .header {
        width: 80%;
        h2 {
          font-size: 3rem;
        }
      }
      .subText {
        width: 90%;
      }
      .formWrapper {
        width: 100%;
        flex-direction: column;
        align-items: center;
        max-width: none;
        justify-content: center;
        height: auto;

        .imageWrapper {
          width: 60%;
          margin-right: 0;
          margin-bottom: 70px;
          display: none;

          .subText {
            width: 100%;
          }
        }
        .form {
          width: 100%;
          p.disclaimer {
            width: 90%;
            margin: 20px auto 0 auto;
          }
          input {
            width: 100%;
            &.full {
              width: 100%;
            }
          }
          input, select {
            font-size: 1.1rem;
          }
          input::placeholder {
            font-size: 1.1rem;
          }
          .sideBySide {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            input {
              width: 48%;
            }
          }
          .personalInfo {
            margin-left: 0px;
            margin-right: 0px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            .sideBySideNum {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: flex-end;
              justify-content: space-around;
              margin-top: 15px;
              margin-bottom: 15px;
              :nth-child(1) {
                margin-right: 0px;
              }

              .item {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-items: center;

                label {
                  width: 100%;
                }
                .dropdown {
                  width: 130px;
                }
              }
            }
            .address {
              padding-top: 0;
            }
            .button {
              Button {
                height: 30px;
                @media all and (max-width: 767px) {
                  height: 70px;
                  border-radius: 50px;
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
