@import "../../styles/variables.scss";

.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(112, 112, 112, 0.75);
  backdrop-filter: blur(50px);

  .modal {
    margin-top: 0px;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    .header {
      position: relative;
      width: 100%;
      max-width: 850px;
      padding-top: 60px;
      padding-bottom: 20px;
      h1 {
        font-size: 5rem;
        line-height: 1;
        text-align: center;
        margin: 0;
        color: white;
      }
      p {
        @include regular-p;
      }
    }
    .buttonWrapper {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      .button {
        margin: 15px;
      }
    }
    .pButton {
      position: relative;
      p {
        color: #fff;
        text-decoration: underline;
        @include regular-p;
        cursor: pointer;
      }
    }
    .logo {
      position: relative;
      width: 15%;
      margin-top: 50px;
    }
  }
}

@media all and (max-width: 767px) {
  .modalContainer {
    .modal {
      .header {
        h1 {
          font-size: 3rem;
        }
      }
      .buttonWrapper {
        flex-direction: column;
      }
      .pButton {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        p {
          font-size: .9rem;
        }
      }
      .logo {
        width: 35%;
      }
    }
  }
}
