@import "../../styles/variables.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  z-index: 201;
  background-color: $black;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(27, 27, 27, 0.75);
  backdrop-filter: blur(50px);
  .logo {
    position: relative;
    width: 180px;
    padding: 25px 0;
    margin-left: 35px;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-right: 40px;
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0 20px;
        a {
          font-size: 1.2em;
          text-decoration: none;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 0;
            height: 1px;
            background-color: #fff;
            transition: width 0.2s;
          }
          &.pageOn,
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .menuBtn {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 35px;
    cursor: pointer;
    .wrap {
      position: relative;
      width: 40px;
      height: 29px;
      padding: 5px;
      span.line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: $yellow;
        border-radius: 3px;
        transition: transform 0.3s, top 0.3s;
        &:nth-child(2) {
          top: 12px;
        }
        &:nth-child(3) {
          top: 24px;
        }
      }
    }
  }
  .modal {
    position: absolute;
    width: 0px;
  }
}

@media all and (max-width: 1300px) {
  .header {
    .nav {
      ul {
        li {
          a {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1160px) {
  .header {
    z-index: 100;
    .logo {
      width: 150px;
    }
    .nav {
      opacity: 0;
      pointer-events: none;
      position: fixed;
      width: 100%;
      top: 120px;
      height: calc(100vh - 120px);
      background-color: $black;
      transition: opacity 0.5s;
      align-items: flex-start;
      ul {
        flex-direction: column;
        margin: 50px auto;
        li {
          margin: 20px 0;
          a {
            font-size: 1.2rem;
          }
        }
      }
    }
    .menuBtn {
      display: block;
    }
  }
}

.menuOn.header {
  .nav {
    opacity: 1;
    pointer-events: all;
  }
  .menuBtn {
    .wrap {
      .line {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 50%;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 50%;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
