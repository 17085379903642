@import "../../styles/variables.scss";

.buttonWrap {
  button {
    padding: 20px 40px;
    font-size: .9em;
    font-family: $maax-bold;
    cursor: pointer;
    font-weight: 400;
    transform: scale(1);
    backface-visibility: hidden;
    transition: transform 0.2s, background-color 0.2s, color 0.2s,
      border-color 0.2s, color 0.2s;
    border: 2px solid $yellow;
    box-sizing: border-box;
    border-radius: 40px;
    background-color: $yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    text-transform: uppercase;

    &:hover {
      background-color: $black;
      color: $yellow;
    }
  }
  &.large {
    button {
      font-size: 1.1rem;
      padding: 20px 40px;
      &:hover {
        background-color: $blue !important;
        color: #fff;
        border-color: $blue !important;
      }
    }
  }
  &.medium {
    button {
      font-size: 1rem;
      height: 50px;
      padding: 0 30px;
      &:hover {
        background-color: $blue !important;
        color: #fff;
        border-color: $blue !important;
      }
    }
  }
}

@media all and (max-width: 1300px) {
  .buttonWrap {
    button {
      font-size: 1rem;
    }
  }
}
