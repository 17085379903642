@import "variables.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: $maax-regular;
}

a {
  color: inherit;
  text-decoration: none;
}

a.btn {
  padding: 20px 40px;
  font-size: 14.4px;
  line-height: 1;
  font-family: $maax-bold;
  cursor: pointer;
  font-weight: 400;
  transform: scale(1);
  backface-visibility: hidden;
  transition: transform 0.2s, background-color 0.2s, color 0.2s,
    border-color 0.2s, color 0.2s;
  border: 2px solid $yellow;
  box-sizing: border-box;
  border-radius: 40px;
  background-color: $yellow;
  display: inline-block;
  color: #000 !important;
  text-transform: uppercase;
  text-decoration: none !important;

  &:hover {
    background-color: $black;
    color: $yellow !important;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-family: $maax-bold;
  font-weight: 400;
}

input,
select {
  font-size: 1rem;
  padding: 15px 15px;
}

input {
  width: 100%;
  font-family: $maax-bold;

  border: 1px solid $darkgray;
  box-sizing: border-box;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 30px;
  @media all and (max-width: 767px) {
    height: 70px;
    font-size: 20px;
  }
}
select {
  width: 100%;
  font-family: $maax-bold;
  border: 1px solid $darkgray;
  box-sizing: border-box;
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 5px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 15px;
  padding-left: 30px;
  background-image: url("/images/down-arrow.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  background-size: 25px;

  @media all and (max-width: 767px) {
    height: 70px;
    font-size: 20px;
    padding-left: 30px;
  }
}

#page_blackBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $black;
  z-index: -1;
}

.rounded_border {
  margin-bottom: 7px;
}

.rounded_border div:first-of-type {
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.rounded_border .css-1wa3eu0-placeholder {
  color: #000;
  font-family: $maax-bold;
}

@media all and (max-width: 767px) {
  .rounded_border {
    margin-top: 10px;
    div:first-of-type {
      font-size: 1.1rem;
      padding-left: 5px;
    }
    input {
      height: 45px;
      font-size: 1.1rem !important;
    }
    input::placeholder {
      font-size: 1.1rem;
    }
  }
}

input::placeholder {
  font-family: $maax-bold;
  color: $black;
}

textarea {
  width: 100%;
  font-family: $maax-bold;
  font-size: 18px;
  border: 1px solid $darkgray;
  box-sizing: border-box;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 20px;
}
textarea::placeholder {
  font-family: $maax-bold;
  color: $black;
  font-size: 18px;
}
label {
  display: flex;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding-left: 20px;
}
main {
  position: relative;
  margin-top: 120px;
  min-height: calc(100vh - 120px);
}

ul.slick-dots li {
  button {
    &:before {
      font-size: 12px;
    }
  }
}

.slick-dots li.slick-active button:before {
  color: $yellow !important;
}

.slick-dots li button:before {
  color: white !important;
  opacity: 1 !important;
}

.inline_image,
.inline_video {
  position: relative;
  margin: 45px auto;
  .youtubeContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .youtubeContainer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

#pypestream-wrapper {
  opacity: 1;
  transition: opacity .3s;
}

.overlay_on {
  #pypestream-wrapper {
    opacity: 0;
    pointer-events: none;
  }
}
